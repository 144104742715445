<template>
  <TheHeader title="The Phonebook" />
  <main>
    <div class="container p-5">
      <PhoneBook />
    </div>
  </main>
  <TheFooter />
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue';
import TheFooter from './components/layout/TheFooter.vue';
import PhoneBook from './components/PhoneBook/PhoneBook.vue';
export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    PhoneBook
  }
};
</script>

<style lang="scss">
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
main {
  flex: 1;
}
.container {
  max-width: 960px !important;
}
main {
  background: #1f2424;
}
footer {
  min-height: 5em;
  background: #121414;
}
nav {
  color: white;
}
h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.75em;
}
h3 {
  font-size: 1.5em;
}
</style>
