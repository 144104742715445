<template>
  <nav class="navbar p-5">
    <div class="container">
      <svg
        enable-background="new 0 0 616.9 132.4"
        viewBox="0 0 616.9 132.4"
        xmlns="http://www.w3.org/2000/svg"
        class="svg-logo"
      >
        <g fill="#03313f" class="letters">
          <path
            d="m199.7 112.5c0 12.5-8.7 19.9-18 19.9s-17.8-7.2-17.8-19.9v-92.6c0-12.5 8.7-19.9 18-19.9s17.8 7.2 17.8 19.9v34.8h-9.3v-35.1c0-6.6-4.2-9.8-8.7-9.8-4.2 0-8.5 3.2-8.5 9.8v93.3c0 6.6 4.3 9.9 8.5 9.9 4.5 0 8.7-3.4 8.7-9.9v-35.2h9.3z"
          />
          <path
            d="m240.6 21.5c0-6.6-4.2-10.3-9-10.3h-8.2v50.5h8.2c4.7 0 9-4 9-10.7zm9.3 108.8h-9.3v-48.4c0-7.1-4.3-10.7-9-10.7h-8.2v59.1h-9.3v-128.2h18.8c9 0 17 6.3 17 18.9v30.6c0 7.2-3.7 12.5-8.7 14.7 5.1 2.2 8.7 8 8.7 15.5z"
          />
          <path
            d="m290.8 19.6c0-6.6-4.2-9.8-8.7-9.8-4.2 0-8.5 3.2-8.5 9.8v93.3c0 6.6 4.3 9.9 8.5 9.9 4.5 0 8.7-3.4 8.7-9.9zm9.3 92.9c0 12.5-8.7 19.9-18 19.9s-17.8-7.2-17.8-19.9v-92.6c0-12.5 8.7-19.9 18-19.9s17.8 7.2 17.8 19.9z"
          />
          <path
            d="m349.4 41.2h-9.3v-21.3c0-5.3-3.2-9.9-8.8-9.9-6.3 0-8.3 5.1-8.3 10.3 0 5.3 1.8 12.5 3.5 17.5l18.6 52.9c2.2 6.4 4.2 14.9 4.2 21.5 0 14.1-8.8 20.4-17.6 20.4-9 0-18-6.6-18-19.6v-21.8h9.3v21.5c0 5.3 3.4 9.9 8.8 9.9 6.1 0 8.3-5.1 8.3-10.3 0-5.3-1.9-13.5-3.7-18.3l-18.8-52.9c-2.2-6.4-4-14.1-4-20.7 0-14.3 8.8-20.4 17.6-20.4 9 0 18.1 6.6 18.1 19.6z"
          />
          <path
            d="m398 41.2h-9.3v-21.3c0-5.3-3.2-9.9-8.8-9.9-6.3 0-8.3 5.1-8.3 10.3 0 5.3 1.8 12.5 3.5 17.5l18.6 52.9c2.2 6.4 4.2 14.9 4.2 21.5 0 14.1-8.8 20.4-17.6 20.4-9 0-18-6.6-18-19.6v-21.8h9.3v21.5c0 5.3 3.4 9.9 8.8 9.9 6.1 0 8.3-5.1 8.3-10.3 0-5.3-1.9-13.5-3.7-18.3l-18.8-52.9c-2.2-6.4-4-14.1-4-20.7 0-14.3 8.8-20.4 17.6-20.4 9 0 18.1 6.6 18.1 19.6z"
          />
          <path
            d="m438.1 81.7c0-7.1-4.2-10.9-8.8-10.9h-8.3v50.3h8.2c4.8 0 9-3.5 9-10.3v-29.1zm0-60.2c0-6.7-4.2-10.3-8.8-10.3h-8.3v50.8h8.2c4.7 0 9-3.8 9-10.6v-29.9zm9.3 91.3c0 11.2-7.9 17.5-16.2 17.5h-19.6v-128.2h19.4c8.3 0 16.3 6.3 16.3 17.6v31.6c0 7.5-3.5 12.7-8.5 14.7 5 2.2 8.5 7.2 8.5 14.6v32.2z"
          />
          <path
            d="m461.8 2.1v128.2h31.7v-9.1h-22.4v-51h16.2v-9.1h-16.2v-49.9h22.4v-9.1z"
          />
          <path
            d="m521.1 37.8 1.3-16.3 1.1 16.3 6.4 56.4h-15.1zm-4.2-35.7-15.2 128.2h9.1l3.2-27.1h16.7l3 27.1h9.5l-15.2-128.2z"
          />
          <path
            d="m604.1 2.1-16.5 90.1-2.2 14.9-2.3-14.9-16.5-90.1h-12.8v128.2h9.3v-88.5l-.2-15.7 2.4 16.1 16.2 88.1h7.7l16.2-88.1 2.4-16.1-.2 15.7v88.5h9.3v-128.2z"
          />
        </g>
        <path
          d="m49.8 90.5h-49.8v39.8h128.3v-39.8h-49.8l-14.4-24.3z"
          fill="#f53251"
        />
        <path
          d="m0 2.1v39.8h49.8l14.3 24.3 14.4-24.3h49.8v-39.8z"
          fill="#77d9e2"
        />
      </svg>
      <h1>{{ title }}</h1>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['title']
};
</script>

<style scoped>
.navbar {
  background: rgb(3, 49, 63);
}
.svg-logo {
  height: 50px;
}
.letters {
  fill: #f2f2f2;
}
.container {
  justify-content: space-between;
}
</style>
