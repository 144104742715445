<template>
  <AddContact
    v-if="showModal"
    title="Add Contact"
    @show-modal="showAddContact"
  />
  <EditContact
    v-if="contactEditIdx !== null"
    title="Edit Contact"
    :contact="contacts[contactEditIdx]"
    @close-edit="resetEditIdx"
  />
  <TheLoader v-if="isLoading" />
  <div>
    <div class="level">
      <div class="level-left">
        <form>
          <div class="select mr-3 ">
            <select
              @change="sorter(nameSort, direction)"
              id="name-sort"
              name="name-sort"
              v-model="nameSort"
            >
              <option value="firstName">First Name</option>
              <option value="lastName">Last Name</option>
            </select>
          </div>
          <div class="select mr-3 ">
            <select
              @change="sorter(nameSort, direction)"
              id="sort-direction"
              name="sort-direction"
              v-model="direction"
            >
              <option value="ascending">Ascending</option>
              <option value="descending">Descending</option>
            </select>
          </div>
        </form>
      </div>
      <div class="level-right">
        <base-button kind="is-light is-link" @click="showAddContact"
          >Add Contact</base-button
        >
      </div>
    </div>
    <div class="contacts">
      <contact
        v-for="(contact, idx) in contacts"
        :key="contact.id"
        :id="contact.id"
        :firstName="contact.firstName"
        :lastName="contact.lastName"
        :avatar="contact.avatar"
        :phone="contact.phone"
        :idx="idx"
        @delete-contact="deleteContact"
        @edit-contact="sendContact"
      ></contact>
    </div>
  </div>
</template>

<script>
import Contact from './Contact.vue';
import AddContact from './AddContact.vue';
import EditContact from './EditContact.vue';
import TheLoader from '../UI/TheLoader.vue';
export default {
  components: {
    Contact,
    AddContact,
    EditContact,
    TheLoader
  },
  data() {
    return {
      showModal: false,
      isLoading: true,
      showEditModal: false,
      contactToEdit: null,
      contactEditIdx: null,
      nameSort: 'firstName',
      direction: 'ascending',
      contacts: JSON.parse(localStorage.getItem('contacts')) || [
        {
          id: 'bob837233',
          firstName: 'Bob',
          lastName: 'Moore',
          phone: '267-234-8796',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4E03AQHI9UPZER0QwQ/profile-displayphoto-shrink_400_400/0/1565214143439?e=1616630400&v=beta&t=BuU7wpYxj_tHlubJuVHYdnJoWm-41pjIeFWkcXJEjec'
        },
        {
          id: 'sakib2838r42',
          firstName: 'Sakib',
          lastName: 'Jamal',
          phone: '215-534-0912',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4E03AQFQG3GK2Hrqag/profile-displayphoto-shrink_400_400/0/1590347770789?e=1616630400&v=beta&t=qVdNU2s8dHiFLe5xDicA-UGbhBKDV1_p2hokstfIEiM'
        },
        {
          id: 'jamie238472',
          firstName: 'Jamie',
          lastName: 'Calabria',
          phone: '267-534-0912',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4E03AQH1xvsCvqgwcw/profile-displayphoto-shrink_400_400/0/1608239863459?e=1617235200&v=beta&t=gKqUbzipR5DrX6nKuJRZR4Dgfeqt0zgIYZD9L_92slU'
        },
        {
          id: 'chrissamila',
          firstName: 'Chris',
          lastName: 'Samila',
          phone: '267-534-3333',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4D03AQHP_UwIW-1f0w/profile-displayphoto-shrink_400_400/0/1603924165097?e=1617235200&v=beta&t=kCsOTPfJb5PiWiZ-ncLhjEgidhsCObjcRVbl3PeNCe4'
        },
        {
          id: 'seanblanda234234',
          firstName: 'Sean',
          lastName: 'Blanda',
          phone: '267-332-3333',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4E03AQG7sY9gmqZgAA/profile-displayphoto-shrink_400_400/0/1572969389105?e=1617235200&v=beta&t=LWjsLLhqjiOWwlxHtkFkB6989jD0qeZiR973GO63ouA'
        },
        {
          id: 'amyrose2938742',
          firstName: 'Amy',
          lastName: 'Rose',
          phone: '215-222-3333',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C5603AQHkLLa-oOFRHQ/profile-displayphoto-shrink_400_400/0/1601859495947?e=1617235200&v=beta&t=eus465WPZgDa_SiHNDEYnrWGLMeB59I-5HiP_rIyQBg'
        },
        {
          id: 'jakewallace234',
          firstName: 'Jake',
          lastName: 'Wallace',
          phone: '215-888-3333',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4E03AQGdxqA4_aDEdA/profile-displayphoto-shrink_400_400/0/1595426627899?e=1617235200&v=beta&t=Mc8Uoix_x-D5QEuR4w31-2OM0Ye2ARZd2QncrgDuwiY'
        },
        {
          id: 'buckryan2342',
          firstName: 'Buck',
          lastName: 'Ryan',
          phone: '215-888-9999',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4E03AQFc7drh8ut_qA/profile-displayphoto-shrink_400_400/0/1557858792637?e=1617235200&v=beta&t=k4MJ71vi9xhQKq0A9p7XTZNwYp3ykXnNB5y6ZAjvoCc'
        },
        {
          id: 'jesswaldeck234234323',
          firstName: 'Jess',
          lastName: 'Waldeck',
          phone: '215-444-9999',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4E03AQHl3Zy9y43r8A/profile-displayphoto-shrink_400_400/0/1516240292312?e=1617235200&v=beta&t=CeHQ9XFrQSkprtqIwedfHLH-Od2P_sVISl3tUya7zqY'
        },
        {
          id: 'lindseyoniell2342',
          firstName: 'Lindsey',
          lastName: "O'Niell",
          phone: '215-444-9999',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4E03AQGMM4yYmR8AQw/profile-displayphoto-shrink_400_400/0/1537382344559?e=1617235200&v=beta&t=EO2u-tYyEJXGFM-0cWh5OapfXsp0NrdcqMCkPUK_2hA'
        },
        {
          id: 'alisonelferaly23423',
          firstName: 'Alison',
          lastName: 'El-Feraly',
          phone: '215-444-5555',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C5603AQH2D8avArSHwQ/profile-displayphoto-shrink_400_400/0/1609205577248?e=1617235200&v=beta&t=szrBMdeFVmdcVjAuPCc2HtFQ8G73N4OmNp08hGcFWn8'
        },
        {
          id: 'melissasarver23423',
          firstName: 'Melissa',
          lastName: 'Sarver',
          phone: '215-444-5555',
          avatar:
            'https://media-exp1.licdn.com/dms/image/C4D03AQFXauIZ9v6b2A/profile-displayphoto-shrink_400_400/0/1517610268301?e=1617235200&v=beta&t=-JoiHe5LBy0Bi8l1zTq3QyN3ybklYDFKB01Nx6Vqc_I'
        }
      ]
    };
  },
  provide() {
    return {
      addContact: this.addContact,
      deleteContact: this.deleteContact,
      sendContact: this.sendContact,
      editContact: this.editContact
    };
  },

  methods: {
    addContact(contact) {
      const newFriend = { id: new Date().toISOString(), ...contact };
      this.contacts.push(newFriend);
      this.sorter(this.nameSort, this.direction);
      this.saveContacts();
    },
    deleteContact(id) {
      const contactIdx = this.contacts.findIndex(contact => contact.id === id);
      this.contacts.splice(contactIdx, 1);
      this.saveContacts();
    },
    showAddContact() {
      this.showModal = !this.showModal;
    },
    resetEditIdx() {
      this.contactEditIdx = null;
    },
    sendContact(id) {
      const contactIdx = this.contacts.findIndex(contact => contact.id === id);
      this.contactEditIdx = contactIdx;
    },
    editContact(contact) {
      const editedContact = { ...contact };
      this.contacts[this.contactEditIdx] = editedContact;
      this.sorter(this.nameSort, this.direction);
      this.saveContacts();
    },
    saveContacts() {
      const parsed = JSON.stringify(this.contacts);
      localStorage.setItem('contacts', parsed);
    },
    sorter(type, direction) {
      this.contacts.sort((a, b) => {
        if (a[type] < b[type]) return -1;
        if (a[type] > b[type]) return 1;
        return 0;
      });
      direction === 'descending' ? this.contacts.reverse() : null;
      if (this.contactEditIdx !== null) this.contactEditIdx = null;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
      if (this.contacts.length > 0) {
        this.sorter(this.nameSort, this.direction);
        this.saveContacts();
      }
    }, 3000);
  }
};
</script>
